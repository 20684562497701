import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _044daa58 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _35297353 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4140aa26 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _539030e7 = () => interopDefault(import('../pages/oskar-courses/index.vue' /* webpackChunkName: "pages/oskar-courses/index" */))
const _01aa8e96 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _7f925c40 = () => interopDefault(import('../pages/unauthenticated-signing-page/index.vue' /* webpackChunkName: "pages/unauthenticated-signing-page/index" */))
const _ae9b62de = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _8aaee7dc = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _2099b7e3 = () => interopDefault(import('../pages/auth/renew-password.vue' /* webpackChunkName: "pages/auth/renew-password" */))
const _3a4c9f3e = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _2c0ba21c = () => interopDefault(import('../pages/auth/switch-account.vue' /* webpackChunkName: "pages/auth/switch-account" */))
const _2effe55d = () => interopDefault(import('../pages/dashboard/bank-accounts.vue' /* webpackChunkName: "pages/dashboard/bank-accounts" */))
const _37a1d319 = () => interopDefault(import('../pages/dashboard/landlords.vue' /* webpackChunkName: "pages/dashboard/landlords" */))
const _45f14d6e = () => interopDefault(import('../pages/dashboard/notifications.vue' /* webpackChunkName: "pages/dashboard/notifications" */))
const _1ffca9c6 = () => interopDefault(import('../pages/dashboard/properties.vue' /* webpackChunkName: "pages/dashboard/properties" */))
const _25832fa6 = () => interopDefault(import('../pages/dashboard/suppliers.vue' /* webpackChunkName: "pages/dashboard/suppliers" */))
const _efc510e2 = () => interopDefault(import('../pages/dashboard/tenants.vue' /* webpackChunkName: "pages/dashboard/tenants" */))
const _251fff48 = () => interopDefault(import('../pages/dashboard/unpaid-costs.vue' /* webpackChunkName: "pages/dashboard/unpaid-costs" */))
const _3fcfdea7 = () => interopDefault(import('../pages/dashboard/unpaid-rent.vue' /* webpackChunkName: "pages/dashboard/unpaid-rent" */))
const _8f15499c = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _821eed26 = () => interopDefault(import('../pages/onboarding/confirm_invitation.vue' /* webpackChunkName: "pages/onboarding/confirm_invitation" */))
const _5486e218 = () => interopDefault(import('../pages/onboarding/demo.vue' /* webpackChunkName: "pages/onboarding/demo" */))
const _fcf6b9d6 = () => interopDefault(import('../pages/settings/company-details.vue' /* webpackChunkName: "pages/settings/company-details" */))
const _177f7404 = () => interopDefault(import('../pages/swan/callback.vue' /* webpackChunkName: "pages/swan/callback" */))
const _4078bbd7 = () => interopDefault(import('../pages/swan/consent-callback.vue' /* webpackChunkName: "pages/swan/consent-callback" */))
const _72138518 = () => interopDefault(import('../pages/dashboard/accounting/listing.vue' /* webpackChunkName: "pages/dashboard/accounting/listing" */))
const _5d9370fa = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-accounts.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-accounts" */))
const _360c1e83 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entries.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entries" */))
const _8869c7ae = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entry-sets.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entry-sets" */))
const _66a4b8b9 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-journals.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-journals" */))
const _e4432876 = () => interopDefault(import('../pages/viewer/CGU/cgu.vue' /* webpackChunkName: "pages/viewer/CGU/cgu" */))
const _123ca0e6 = () => interopDefault(import('../pages/workflow/account-statement/create.vue' /* webpackChunkName: "pages/workflow/account-statement/create" */))
const _1fa2ac6c = () => interopDefault(import('../pages/workflow/landlord/create.vue' /* webpackChunkName: "pages/workflow/landlord/create" */))
const _718ae796 = () => interopDefault(import('../pages/workflow/property/create.vue' /* webpackChunkName: "pages/workflow/property/create" */))
const _23441fde = () => interopDefault(import('../pages/workflow/supplier/create.vue' /* webpackChunkName: "pages/workflow/supplier/create" */))
const _65cb9000 = () => interopDefault(import('../pages/workflow/tenant/create.vue' /* webpackChunkName: "pages/workflow/tenant/create" */))
const _c4579d3c = () => interopDefault(import('../pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index.vue' /* webpackChunkName: "pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index" */))
const _2d9e9838 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/index" */))
const _6a158068 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/index" */))
const _5dcad03e = () => interopDefault(import('../pages/viewer/file/_fileId.vue' /* webpackChunkName: "pages/viewer/file/_fileId" */))
const _f68f8e6e = () => interopDefault(import('../pages/viewer/inventory/_inventoryId.vue' /* webpackChunkName: "pages/viewer/inventory/_inventoryId" */))
const _293071b2 = () => interopDefault(import('../pages/viewer/management-report/_managementReportId.vue' /* webpackChunkName: "pages/viewer/management-report/_managementReportId" */))
const _6e1b4249 = () => interopDefault(import('../pages/viewer/mandate/_mandateId.vue' /* webpackChunkName: "pages/viewer/mandate/_mandateId" */))
const _59b05009 = () => interopDefault(import('../pages/viewer/payment/_paymentId.vue' /* webpackChunkName: "pages/viewer/payment/_paymentId" */))
const _74e8a3ca = () => interopDefault(import('../pages/viewer/rent/_rentId.vue' /* webpackChunkName: "pages/viewer/rent/_rentId" */))
const _53cb459e = () => interopDefault(import('../pages/viewer/rental/_rentalId.vue' /* webpackChunkName: "pages/viewer/rental/_rentalId" */))
const _2e3c6fc4 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/documents" */))
const _e2a3a35e = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/informations" */))
const _5a079982 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/management-reports" */))
const _037a693d = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/properties.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/properties" */))
const _24b285b2 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/documents" */))
const _570b983d = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/informations" */))
const _6a5b8ae5 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/payment-methods" */))
const _11caa7c9 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rentals.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rentals" */))
const _f679c158 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rents" */))
const _5ecb9df5 = () => interopDefault(import('../pages/workflow/property/_propertyId/cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/cost/create" */))
const _0055b6a8 = () => interopDefault(import('../pages/workflow/property/_propertyId/inventory/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/inventory/create" */))
const _40bd2175 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/create.old.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/create.old" */))
const _460b1af8 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/form.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/form" */))
const _276dd709 = () => interopDefault(import('../pages/workflow/property/_propertyId/rent/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rent/create" */))
const _62e1287e = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/create" */))
const _48991834 = () => interopDefault(import('../pages/workflow/property/_propertyId/tenant-cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/tenant-cost/create" */))
const _53938791 = () => interopDefault(import('../pages/viewer/property/_propertyId/cost/_costId.vue' /* webpackChunkName: "pages/viewer/property/_propertyId/cost/_costId" */))
const _d16a7f4e = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index" */))
const _028dca1d = () => interopDefault(import('../pages/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/landlord/_landlordId/index" */))
const _075d9c72 = () => interopDefault(import('../pages/property/_propertyId/index.vue' /* webpackChunkName: "pages/property/_propertyId/index" */))
const _2c0ccbf5 = () => interopDefault(import('../pages/supplier/_supplierId/index.vue' /* webpackChunkName: "pages/supplier/_supplierId/index" */))
const _81a9a79e = () => interopDefault(import('../pages/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/tenant/_tenantId/index" */))
const _6d6b7f83 = () => interopDefault(import('../pages/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/landlord/_landlordId/documents" */))
const _24e300cc = () => interopDefault(import('../pages/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/landlord/_landlordId/informations" */))
const _3969fd7a = () => interopDefault(import('../pages/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/landlord/_landlordId/management-reports" */))
const _51a90298 = () => interopDefault(import('../pages/landlord/_landlordId/payouts.vue' /* webpackChunkName: "pages/landlord/_landlordId/payouts" */))
const _139fad8e = () => interopDefault(import('../pages/landlord/_landlordId/update.vue' /* webpackChunkName: "pages/landlord/_landlordId/update" */))
const _05732496 = () => interopDefault(import('../pages/property/_propertyId/contracts.vue' /* webpackChunkName: "pages/property/_propertyId/contracts" */))
const _1d67793b = () => interopDefault(import('../pages/property/_propertyId/costs.vue' /* webpackChunkName: "pages/property/_propertyId/costs" */))
const _fb01fba6 = () => interopDefault(import('../pages/property/_propertyId/documents.vue' /* webpackChunkName: "pages/property/_propertyId/documents" */))
const _93c1dd62 = () => interopDefault(import('../pages/property/_propertyId/rents.vue' /* webpackChunkName: "pages/property/_propertyId/rents" */))
const _52493b24 = () => interopDefault(import('../pages/property/_propertyId/update.vue' /* webpackChunkName: "pages/property/_propertyId/update" */))
const _b4a28818 = () => interopDefault(import('../pages/supplier/_supplierId/informations.vue' /* webpackChunkName: "pages/supplier/_supplierId/informations" */))
const _1a00e6b6 = () => interopDefault(import('../pages/supplier/_supplierId/update.vue' /* webpackChunkName: "pages/supplier/_supplierId/update" */))
const _0cbc90d2 = () => interopDefault(import('../pages/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/tenant/_tenantId/documents" */))
const _832a3f90 = () => interopDefault(import('../pages/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/tenant/_tenantId/informations" */))
const _195605ec = () => interopDefault(import('../pages/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/tenant/_tenantId/payment-methods" */))
const _2aa2e40c = () => interopDefault(import('../pages/tenant/_tenantId/update.vue' /* webpackChunkName: "pages/tenant/_tenantId/update" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _044daa58,
    name: "dashboard___fr"
  }, {
    path: "/en",
    component: _35297353,
    name: "index___en"
  }, {
    path: "/onboarding",
    component: _4140aa26,
    name: "onboarding___fr"
  }, {
    path: "/oskar-courses",
    component: _539030e7,
    name: "oskar-courses___fr"
  }, {
    path: "/settings",
    component: _01aa8e96,
    name: "settings___fr"
  }, {
    path: "/unauthenticated-signing-page",
    component: _7f925c40,
    name: "unauthenticated-signing-page___fr"
  }, {
    path: "/auth/login",
    component: _ae9b62de,
    name: "auth-login___fr"
  }, {
    path: "/auth/logout",
    component: _8aaee7dc,
    name: "auth-logout___fr"
  }, {
    path: "/auth/renew-password",
    component: _2099b7e3,
    name: "auth-renew-password___fr"
  }, {
    path: "/auth/reset-password",
    component: _3a4c9f3e,
    name: "auth-reset-password___fr"
  }, {
    path: "/auth/switch-account",
    component: _2c0ba21c,
    name: "auth-switch-account___fr"
  }, {
    path: "/dashboard/bank-accounts",
    component: _2effe55d,
    name: "dashboard-bank-accounts___fr"
  }, {
    path: "/dashboard/landlords",
    component: _37a1d319,
    name: "dashboard-landlords___fr"
  }, {
    path: "/dashboard/notifications",
    component: _45f14d6e,
    name: "dashboard-notifications___fr"
  }, {
    path: "/dashboard/properties",
    component: _1ffca9c6,
    name: "dashboard-properties___fr"
  }, {
    path: "/dashboard/suppliers",
    component: _25832fa6,
    name: "dashboard-suppliers___fr"
  }, {
    path: "/dashboard/tenants",
    component: _efc510e2,
    name: "dashboard-tenants___fr"
  }, {
    path: "/dashboard/unpaid-costs",
    component: _251fff48,
    name: "dashboard-unpaid-costs___fr"
  }, {
    path: "/dashboard/unpaid-rent",
    component: _3fcfdea7,
    name: "dashboard-unpaid-rent___fr"
  }, {
    path: "/en/dashboard",
    component: _044daa58,
    name: "dashboard___en"
  }, {
    path: "/en/onboarding",
    component: _4140aa26,
    name: "onboarding___en"
  }, {
    path: "/en/oskar-courses",
    component: _539030e7,
    name: "oskar-courses___en"
  }, {
    path: "/en/settings",
    component: _01aa8e96,
    name: "settings___en"
  }, {
    path: "/en/unauthenticated-signing-page",
    component: _7f925c40,
    name: "unauthenticated-signing-page___en"
  }, {
    path: "/error/404",
    component: _8f15499c,
    name: "error-404___fr"
  }, {
    path: "/onboarding/confirm_invitation",
    component: _821eed26,
    name: "onboarding-confirm_invitation___fr"
  }, {
    path: "/onboarding/demo",
    component: _5486e218,
    name: "onboarding-demo___fr"
  }, {
    path: "/settings/company-details",
    component: _fcf6b9d6,
    name: "settings-company-details___fr"
  }, {
    path: "/swan/callback",
    component: _177f7404,
    name: "swan-callback___fr"
  }, {
    path: "/swan/consent-callback",
    component: _4078bbd7,
    name: "swan-consent-callback___fr"
  }, {
    path: "/dashboard/accounting/listing",
    component: _72138518,
    name: "dashboard-accounting-listing___fr",
    children: [{
      path: "accounting-accounts",
      component: _5d9370fa,
      name: "dashboard-accounting-listing-accounting-accounts___fr"
    }, {
      path: "accounting-entries",
      component: _360c1e83,
      name: "dashboard-accounting-listing-accounting-entries___fr"
    }, {
      path: "accounting-entry-sets",
      component: _8869c7ae,
      name: "dashboard-accounting-listing-accounting-entry-sets___fr"
    }, {
      path: "accounting-journals",
      component: _66a4b8b9,
      name: "dashboard-accounting-listing-accounting-journals___fr"
    }]
  }, {
    path: "/en/auth/login",
    component: _ae9b62de,
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _8aaee7dc,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/renew-password",
    component: _2099b7e3,
    name: "auth-renew-password___en"
  }, {
    path: "/en/auth/reset-password",
    component: _3a4c9f3e,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/switch-account",
    component: _2c0ba21c,
    name: "auth-switch-account___en"
  }, {
    path: "/en/dashboard/bank-accounts",
    component: _2effe55d,
    name: "dashboard-bank-accounts___en"
  }, {
    path: "/en/dashboard/landlords",
    component: _37a1d319,
    name: "dashboard-landlords___en"
  }, {
    path: "/en/dashboard/notifications",
    component: _45f14d6e,
    name: "dashboard-notifications___en"
  }, {
    path: "/en/dashboard/properties",
    component: _1ffca9c6,
    name: "dashboard-properties___en"
  }, {
    path: "/en/dashboard/suppliers",
    component: _25832fa6,
    name: "dashboard-suppliers___en"
  }, {
    path: "/en/dashboard/tenants",
    component: _efc510e2,
    name: "dashboard-tenants___en"
  }, {
    path: "/en/dashboard/unpaid-costs",
    component: _251fff48,
    name: "dashboard-unpaid-costs___en"
  }, {
    path: "/en/dashboard/unpaid-rent",
    component: _3fcfdea7,
    name: "dashboard-unpaid-rent___en"
  }, {
    path: "/en/error/404",
    component: _8f15499c,
    name: "error-404___en"
  }, {
    path: "/en/onboarding/confirm_invitation",
    component: _821eed26,
    name: "onboarding-confirm_invitation___en"
  }, {
    path: "/en/onboarding/demo",
    component: _5486e218,
    name: "onboarding-demo___en"
  }, {
    path: "/en/settings/company-details",
    component: _fcf6b9d6,
    name: "settings-company-details___en"
  }, {
    path: "/en/swan/callback",
    component: _177f7404,
    name: "swan-callback___en"
  }, {
    path: "/en/swan/consent-callback",
    component: _4078bbd7,
    name: "swan-consent-callback___en"
  }, {
    path: "/viewer/CGU/cgu",
    component: _e4432876,
    name: "viewer-CGU-cgu___fr"
  }, {
    path: "/workflow/account-statement/create",
    component: _123ca0e6,
    name: "workflow-account-statement-create___fr"
  }, {
    path: "/workflow/landlord/create",
    component: _1fa2ac6c,
    name: "workflow-landlord-create___fr"
  }, {
    path: "/workflow/property/create",
    component: _718ae796,
    name: "workflow-property-create___fr"
  }, {
    path: "/workflow/supplier/create",
    component: _23441fde,
    name: "workflow-supplier-create___fr"
  }, {
    path: "/workflow/tenant/create",
    component: _65cb9000,
    name: "workflow-tenant-create___fr"
  }, {
    path: "/en/dashboard/accounting/listing",
    component: _72138518,
    name: "dashboard-accounting-listing___en",
    children: [{
      path: "accounting-accounts",
      component: _5d9370fa,
      name: "dashboard-accounting-listing-accounting-accounts___en"
    }, {
      path: "accounting-entries",
      component: _360c1e83,
      name: "dashboard-accounting-listing-accounting-entries___en"
    }, {
      path: "accounting-entry-sets",
      component: _8869c7ae,
      name: "dashboard-accounting-listing-accounting-entry-sets___en"
    }, {
      path: "accounting-journals",
      component: _66a4b8b9,
      name: "dashboard-accounting-listing-accounting-journals___en"
    }]
  }, {
    path: "/en/viewer/CGU/cgu",
    component: _e4432876,
    name: "viewer-CGU-cgu___en"
  }, {
    path: "/en/workflow/account-statement/create",
    component: _123ca0e6,
    name: "workflow-account-statement-create___en"
  }, {
    path: "/en/workflow/landlord/create",
    component: _1fa2ac6c,
    name: "workflow-landlord-create___en"
  }, {
    path: "/en/workflow/property/create",
    component: _718ae796,
    name: "workflow-property-create___en"
  }, {
    path: "/en/workflow/supplier/create",
    component: _23441fde,
    name: "workflow-supplier-create___en"
  }, {
    path: "/en/workflow/tenant/create",
    component: _65cb9000,
    name: "workflow-tenant-create___en"
  }, {
    path: "/en/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _c4579d3c,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___en"
  }, {
    path: "/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _c4579d3c,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___fr"
  }, {
    path: "/en/dashboard/landlord/:landlordId",
    component: _2d9e9838,
    name: "dashboard-landlord-landlordId___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId",
    component: _6a158068,
    name: "dashboard-tenant-tenantId___en"
  }, {
    path: "/en/viewer/file/:fileId?",
    component: _5dcad03e,
    name: "viewer-file-fileId___en"
  }, {
    path: "/en/viewer/inventory/:inventoryId?",
    component: _f68f8e6e,
    name: "viewer-inventory-inventoryId___en"
  }, {
    path: "/en/viewer/management-report/:managementReportId?",
    component: _293071b2,
    name: "viewer-management-report-managementReportId___en"
  }, {
    path: "/en/viewer/mandate/:mandateId?",
    component: _6e1b4249,
    name: "viewer-mandate-mandateId___en"
  }, {
    path: "/en/viewer/payment/:paymentId?",
    component: _59b05009,
    name: "viewer-payment-paymentId___en"
  }, {
    path: "/en/viewer/rent/:rentId?",
    component: _74e8a3ca,
    name: "viewer-rent-rentId___en"
  }, {
    path: "/en/viewer/rental/:rentalId?",
    component: _53cb459e,
    name: "viewer-rental-rentalId___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/documents",
    component: _2e3c6fc4,
    name: "dashboard-landlord-landlordId-documents___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/informations",
    component: _e2a3a35e,
    name: "dashboard-landlord-landlordId-informations___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/management-reports",
    component: _5a079982,
    name: "dashboard-landlord-landlordId-management-reports___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/properties",
    component: _037a693d,
    name: "dashboard-landlord-landlordId-properties___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/documents",
    component: _24b285b2,
    name: "dashboard-tenant-tenantId-documents___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/informations",
    component: _570b983d,
    name: "dashboard-tenant-tenantId-informations___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/payment-methods",
    component: _6a5b8ae5,
    name: "dashboard-tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rentals",
    component: _11caa7c9,
    name: "dashboard-tenant-tenantId-rentals___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rents",
    component: _f679c158,
    name: "dashboard-tenant-tenantId-rents___en"
  }, {
    path: "/en/workflow/property/:propertyId?/cost/create",
    component: _5ecb9df5,
    name: "workflow-property-propertyId-cost-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/inventory/create",
    component: _0055b6a8,
    name: "workflow-property-propertyId-inventory-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/create.old",
    component: _40bd2175,
    name: "workflow-property-propertyId-mandate-create.old___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/form",
    component: _460b1af8,
    name: "workflow-property-propertyId-mandate-form___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rent/create",
    component: _276dd709,
    name: "workflow-property-propertyId-rent-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/create",
    component: _62e1287e,
    name: "workflow-property-propertyId-rental-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/tenant-cost/create",
    component: _48991834,
    name: "workflow-property-propertyId-tenant-cost-create___en"
  }, {
    path: "/en/viewer/property/:propertyId?/cost/:costId?",
    component: _53938791,
    name: "viewer-property-propertyId-cost-costId___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _d16a7f4e,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___en"
  }, {
    path: "/dashboard/landlord/:landlordId",
    component: _2d9e9838,
    name: "dashboard-landlord-landlordId___fr"
  }, {
    path: "/dashboard/tenant/:tenantId",
    component: _6a158068,
    name: "dashboard-tenant-tenantId___fr"
  }, {
    path: "/en/landlord/:landlordId",
    component: _028dca1d,
    name: "landlord-landlordId___en"
  }, {
    path: "/en/property/:propertyId",
    component: _075d9c72,
    name: "property-propertyId___en"
  }, {
    path: "/en/supplier/:supplierId",
    component: _2c0ccbf5,
    name: "supplier-supplierId___en"
  }, {
    path: "/en/tenant/:tenantId",
    component: _81a9a79e,
    name: "tenant-tenantId___en"
  }, {
    path: "/viewer/file/:fileId?",
    component: _5dcad03e,
    name: "viewer-file-fileId___fr"
  }, {
    path: "/viewer/inventory/:inventoryId?",
    component: _f68f8e6e,
    name: "viewer-inventory-inventoryId___fr"
  }, {
    path: "/viewer/management-report/:managementReportId?",
    component: _293071b2,
    name: "viewer-management-report-managementReportId___fr"
  }, {
    path: "/viewer/mandate/:mandateId?",
    component: _6e1b4249,
    name: "viewer-mandate-mandateId___fr"
  }, {
    path: "/viewer/payment/:paymentId?",
    component: _59b05009,
    name: "viewer-payment-paymentId___fr"
  }, {
    path: "/viewer/rent/:rentId?",
    component: _74e8a3ca,
    name: "viewer-rent-rentId___fr"
  }, {
    path: "/viewer/rental/:rentalId?",
    component: _53cb459e,
    name: "viewer-rental-rentalId___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/documents",
    component: _2e3c6fc4,
    name: "dashboard-landlord-landlordId-documents___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/informations",
    component: _e2a3a35e,
    name: "dashboard-landlord-landlordId-informations___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/management-reports",
    component: _5a079982,
    name: "dashboard-landlord-landlordId-management-reports___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/properties",
    component: _037a693d,
    name: "dashboard-landlord-landlordId-properties___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/documents",
    component: _24b285b2,
    name: "dashboard-tenant-tenantId-documents___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/informations",
    component: _570b983d,
    name: "dashboard-tenant-tenantId-informations___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/payment-methods",
    component: _6a5b8ae5,
    name: "dashboard-tenant-tenantId-payment-methods___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rentals",
    component: _11caa7c9,
    name: "dashboard-tenant-tenantId-rentals___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rents",
    component: _f679c158,
    name: "dashboard-tenant-tenantId-rents___fr"
  }, {
    path: "/en/landlord/:landlordId?/documents",
    component: _6d6b7f83,
    name: "landlord-landlordId-documents___en"
  }, {
    path: "/en/landlord/:landlordId?/informations",
    component: _24e300cc,
    name: "landlord-landlordId-informations___en"
  }, {
    path: "/en/landlord/:landlordId?/management-reports",
    component: _3969fd7a,
    name: "landlord-landlordId-management-reports___en"
  }, {
    path: "/en/landlord/:landlordId?/payouts",
    component: _51a90298,
    name: "landlord-landlordId-payouts___en"
  }, {
    path: "/en/landlord/:landlordId?/update",
    component: _139fad8e,
    name: "landlord-landlordId-update___en"
  }, {
    path: "/en/property/:propertyId?/contracts",
    component: _05732496,
    name: "property-propertyId-contracts___en"
  }, {
    path: "/en/property/:propertyId?/costs",
    component: _1d67793b,
    name: "property-propertyId-costs___en"
  }, {
    path: "/en/property/:propertyId?/documents",
    component: _fb01fba6,
    name: "property-propertyId-documents___en"
  }, {
    path: "/en/property/:propertyId?/rents",
    component: _93c1dd62,
    name: "property-propertyId-rents___en"
  }, {
    path: "/en/property/:propertyId?/update",
    component: _52493b24,
    name: "property-propertyId-update___en"
  }, {
    path: "/en/supplier/:supplierId?/informations",
    component: _b4a28818,
    name: "supplier-supplierId-informations___en"
  }, {
    path: "/en/supplier/:supplierId?/update",
    component: _1a00e6b6,
    name: "supplier-supplierId-update___en"
  }, {
    path: "/en/tenant/:tenantId?/documents",
    component: _0cbc90d2,
    name: "tenant-tenantId-documents___en"
  }, {
    path: "/en/tenant/:tenantId?/informations",
    component: _832a3f90,
    name: "tenant-tenantId-informations___en"
  }, {
    path: "/en/tenant/:tenantId?/payment-methods",
    component: _195605ec,
    name: "tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/tenant/:tenantId?/update",
    component: _2aa2e40c,
    name: "tenant-tenantId-update___en"
  }, {
    path: "/workflow/property/:propertyId?/cost/create",
    component: _5ecb9df5,
    name: "workflow-property-propertyId-cost-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/inventory/create",
    component: _0055b6a8,
    name: "workflow-property-propertyId-inventory-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/create.old",
    component: _40bd2175,
    name: "workflow-property-propertyId-mandate-create.old___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/form",
    component: _460b1af8,
    name: "workflow-property-propertyId-mandate-form___fr"
  }, {
    path: "/workflow/property/:propertyId?/rent/create",
    component: _276dd709,
    name: "workflow-property-propertyId-rent-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/create",
    component: _62e1287e,
    name: "workflow-property-propertyId-rental-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/tenant-cost/create",
    component: _48991834,
    name: "workflow-property-propertyId-tenant-cost-create___fr"
  }, {
    path: "/viewer/property/:propertyId?/cost/:costId?",
    component: _53938791,
    name: "viewer-property-propertyId-cost-costId___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _d16a7f4e,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___fr"
  }, {
    path: "/landlord/:landlordId",
    component: _028dca1d,
    name: "landlord-landlordId___fr"
  }, {
    path: "/property/:propertyId",
    component: _075d9c72,
    name: "property-propertyId___fr"
  }, {
    path: "/supplier/:supplierId",
    component: _2c0ccbf5,
    name: "supplier-supplierId___fr"
  }, {
    path: "/tenant/:tenantId",
    component: _81a9a79e,
    name: "tenant-tenantId___fr"
  }, {
    path: "/landlord/:landlordId?/documents",
    component: _6d6b7f83,
    name: "landlord-landlordId-documents___fr"
  }, {
    path: "/landlord/:landlordId?/informations",
    component: _24e300cc,
    name: "landlord-landlordId-informations___fr"
  }, {
    path: "/landlord/:landlordId?/management-reports",
    component: _3969fd7a,
    name: "landlord-landlordId-management-reports___fr"
  }, {
    path: "/landlord/:landlordId?/payouts",
    component: _51a90298,
    name: "landlord-landlordId-payouts___fr"
  }, {
    path: "/landlord/:landlordId?/update",
    component: _139fad8e,
    name: "landlord-landlordId-update___fr"
  }, {
    path: "/property/:propertyId?/contracts",
    component: _05732496,
    name: "property-propertyId-contracts___fr"
  }, {
    path: "/property/:propertyId?/costs",
    component: _1d67793b,
    name: "property-propertyId-costs___fr"
  }, {
    path: "/property/:propertyId?/documents",
    component: _fb01fba6,
    name: "property-propertyId-documents___fr"
  }, {
    path: "/property/:propertyId?/rents",
    component: _93c1dd62,
    name: "property-propertyId-rents___fr"
  }, {
    path: "/property/:propertyId?/update",
    component: _52493b24,
    name: "property-propertyId-update___fr"
  }, {
    path: "/supplier/:supplierId?/informations",
    component: _b4a28818,
    name: "supplier-supplierId-informations___fr"
  }, {
    path: "/supplier/:supplierId?/update",
    component: _1a00e6b6,
    name: "supplier-supplierId-update___fr"
  }, {
    path: "/tenant/:tenantId?/documents",
    component: _0cbc90d2,
    name: "tenant-tenantId-documents___fr"
  }, {
    path: "/tenant/:tenantId?/informations",
    component: _832a3f90,
    name: "tenant-tenantId-informations___fr"
  }, {
    path: "/tenant/:tenantId?/payment-methods",
    component: _195605ec,
    name: "tenant-tenantId-payment-methods___fr"
  }, {
    path: "/tenant/:tenantId?/update",
    component: _2aa2e40c,
    name: "tenant-tenantId-update___fr"
  }, {
    path: "/",
    component: _35297353,
    name: "index___fr"
  }],

  parseQuery: function(queryString) {
            return require('qs').parse(queryString)
        },
  stringifyQuery: function(object) {
            var queryString = require('qs').stringify(object)
            return queryString ? '?' + queryString : ''
        },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
